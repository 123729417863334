<template>
	<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" @select="pinSelect" @sort-change="$emit('sortChange', $event)" size="mini" :default-sort ="defaultSort">
		<el-table-column v-if="!readonly" type="selection" width="55" :selectable="rowSelectable">
		</el-table-column>
		<el-table-column label="站点" prop="channel" min-width="120" align="center">
		</el-table-column>
		<el-table-column label="日期" min-width="120" align="center">
			<template slot-scope="scope">
				{{scope.row.createdAt | formatDate('YYYY-MM-DD') }}
			</template>
		</el-table-column>
		<el-table-column label="商品id" prop="spu" min-width="100" align="center">
			<template slot-scope="scope">
				{{getSpu(scope.row)}}
			</template>
		</el-table-column>
		<el-table-column label="来源id" min-width="100" align="center">
			<template slot-scope="scope">
				{{getSource(scope.row)}}
			</template>
		</el-table-column>
		<el-table-column label="同步状态" min-width="150" align="center">
			<template slot-scope="scope">
				<product-sync-status :syncStatus="scope.row.syncStatus"></product-sync-status>
			</template>
		</el-table-column>
		<el-table-column label="品类" prop="category" min-width="200" align="center">
		</el-table-column>
		<el-table-column label="商品主图" min-width="100" align="center">
			<template slot-scope="scope">
				<div v-if="scope.row.image" class="table-img-box">
					<el-image :src="scope.row.image" :preview-src-list="[scope.row.image]"></el-image>
				</div>
			</template>
		</el-table-column>
		<el-table-column label="选款规则" prop="rule" min-width="200" align="center">
			<template slot="header">
				<header-with-tip :infos="tipInfo.rule" title="选款规则"></header-with-tip>
			</template>
			<template slot-scope="scope">
				{{scope.row.rule|findLabelByKey(recommendRules, 'value', 'label')}}
			</template>
		</el-table-column>
		<el-table-column label="IMP" min-width="100" sortable="custom" prop="tps.list_view_total" align="center">
			<template slot="header">
				<header-with-tip :infos="tipInfo.imp" title="IMP"></header-with-tip>
			</template>
			<template slot-scope="scope">
				{{getImp(scope.row)}}
			</template>
		</el-table-column>
		<el-table-column label="CLICKS" min-width="200" sortable="custom" prop="tps.list_click_total" align="center">
			<template slot="header">
				<header-with-tip :infos="tipInfo.clicks" title="CLICKS"></header-with-tip>
			</template>
			<template slot-scope="scope">
				{{getClick(scope.row)}}
			</template>
		</el-table-column>
		<el-table-column label="CTR" min-width="100" sortable="custom" prop="ctr_total" align="center">
			<template slot="header">
				<header-with-tip :infos="tipInfo.ctr" title="CTR"></header-with-tip>
			</template>
			<template slot-scope="scope">
				{{getCTR(scope.row)}}
			</template>
		</el-table-column>
		<el-table-column label="VIEWS" min-width="120" sortable="custom" prop="details_view_total" align="center">
			<template slot="header">
				<header-with-tip :infos="tipInfo.views" title="VIEWS"></header-with-tip>
			</template>
			<template slot-scope="scope">
				{{getViews(scope.row)}}
			</template>
		</el-table-column>
		<el-table-column label="ADD TO CART" min-width="200" sortable="custom" prop="cart_add_total" align="center">
			<template slot="header">
				<header-with-tip :infos="tipInfo.cart" title="ADD TO CART"></header-with-tip>
			</template>
			<template slot-scope="scope">
				{{getCart(scope.row)}}
			</template>
		</el-table-column>
		<!-- <el-table-column label="CHECKOUT" min-width="150" align="center">
			<template slot="header">
				<header-with-tip :infos="tipInfo.checkout" title="CHECKOUT"></header-with-tip>
			</template>
			<template slot-scope="scope">
				{{getCheckout(scope.row)}}
			</template>
		</el-table-column> -->
		<el-table-column label="SALES" min-width="120" sortable="custom" prop="tps.sales_total" align="center">
			<template slot="header">
				<header-with-tip :infos="tipInfo.sales" title="SALES"></header-with-tip>
			</template>
			<template slot-scope="scope">
				{{getSales(scope.row)}}
			</template>
		</el-table-column>
		<!-- <el-table-column label="CART RATE" min-width="150" align="center">
			<template slot="header">
				<header-with-tip :infos="tipInfo.cartRate" title="CART RATE"></header-with-tip>
			</template>
			<template slot-scope="scope">
				{{getCartRate(scope.row)}}
			</template>
		</el-table-column>
		<el-table-column label="CHECKOUT RATE" min-width="200" align="center">
			<template slot="header">
				<header-with-tip :infos="tipInfo.checkoutRate" title="CHECKOUT RATE"></header-with-tip>
			</template>
			<template slot-scope="scope">
				{{getCheckoutRate(scope.row)}}
			</template>
		</el-table-column> -->
		<el-table-column label="CR" min-width="100" sortable="custom" prop="tps.cr_total" align="center">
			<template slot="header">
				<header-with-tip :infos="tipInfo.cr" title="CR"></header-with-tip>
			</template>
			<template slot-scope="scope">
				{{getCR(scope.row)}}
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import ProductSyncStatus from './product-sync-status.vue'
	import HeaderWithTip from './header-with-tip.vue'
	import {mapGetters} from 'vuex'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: []
			},
			readonly: {
				type: Boolean,
				default: false
			},
			defaultSort: {
				type: Object,
				default: (() => {
					return {prop: 'tps.sales_total', order: 'descending'}
				})
			}
		},
		components: {
			ProductSyncStatus,
			HeaderWithTip
		},
		data() {
			return {
				tipInfo: {
					rule: [
						'Manual :  手动选款，商品数据为过去14d',
						'热销款： 自动选款，商品数据为过去14d；规则： impressions > 1000，sales>= 50，views > 10，cr >6(0.0006*10000)，ctr>1，views > clicks',
						'潜力款:  自动选款，商品数据为过去7d；规则：impressions > 500，18> sales >2，views > 10，cr >8(0.0008*10000)，ctr>1，views > clicks'
					],
					imp: [
						'impressions，商品在列表页的曝光数量'
					],
					clicks: [
						'clicks，商品在列表页的点击数量'
					],
					ctr: [
						'ctr=clicks/impressions*100，商品在列表页的点击率'
					],
					views: [
						'views，商品详情的展示次数'
					],
					cart: [
						'add to cart， 商品详情的加车数量'
					],
					checkout: [
						'checkout， 商品发起支付的数量'
					],
					sales: [
						'order， 商品的订单数量'
					],
					cartRate: [
						'cart rate=add to cart/views，商品加车转化率'
					],
					checkoutRate: [
						'chekout rate=checkout/views，商品发起支付转化率'
					],
					cr: [
						'CR=order/views*100*CTR，商品转化率'
					]
				}
			}
		},
		computed: {
			...mapGetters({
				recommendRules: 'fb-carousel-ads/recommendRules'
			})
		},
		methods: {
			rowSelectable(row, index) {
				return row.syncStatus !== null
			},
			constructRow(l, r,) {
				const _l = l !== null ? l : '--'
				const _r = r !== null ? r : '--'
				return `${_l} | ${_r}`
			},
			getSpu(row) {
				return row.spu || '--'
			},
			getSource(row) {
				return [row.sourceWebsite, row.sourceSpu].filter(v => v !== null).join('-')
			},
			getImp(row) {
				return this.constructRow(row.listViewTotal, row.listView)
			},
			getClick(row) {
				return this.constructRow(row.listClickTotal, row.listClick)
			},
			getCTR(row) {
				return this.constructRow(
					row.ctrTotal && row.ctrTotal.toFixed(1),
					row.ctr && row.ctr.toFixed(1)
				)
			},
			getViews(row) {
				return this.constructRow(row.detailsViewTotal, row.detailsView)
			},
			getCart(row) {
				return this.constructRow(row.cartAddTotal, row.cartAdd)
			},
			getCheckout(row) {
				return this.constructRow(row.itemQuantityTotal, row.itemQuantity)
			},
			getSales(row) {
				return this.constructRow(row.salesTotal, row.sales)
			},
			getCartRate(row) {},
			getCheckoutRate(row) {},
			getCR(row) {
				return this.constructRow(
					row.crTotal && row.crTotal.toFixed(1),
					row.cr && row.cr.toFixed(1)
				)
			}
		}
	}
</script>

<style>
</style>
