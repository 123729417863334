<template>
	<div v-show="syncStatus" :class="syncStatus|productSyncStatusClasses(syncStatusList)">{{syncStatus|productSyncLabel(syncStatusList)}}</div>
</template>

<script>
	export default {
		props: {
			syncStatus: {
				type: String
			}
		},
		data() {
			return {
				syncStatusList: {
					PUBLISHED_AVAILABLE: {
						label: '已同步-在架',
						value: 'success'
					},
					PUBLISHED_DRAFT: {
						label: '已同步-draft状态',
						value: 'warning'
					},
					PUBLISHED_MISS_ONLINE: {
						label: '已同步-无online渠道',
						value: 'warning'
					},
					PUBLISHED_UNAVAILABLE: {
						label: '已同步-下架',
						value: 'error'
					},
					UN_PUBLISHED: {
						label: '未同步',
						value: 'error'
					},
					PUBLISHED_DOWNLOADING: {
						label: '已同步--更新中',
						value: 'warning'
					}
				}
			}
		},
		filters: {
			productSyncLabel(key, syncStatusList) {
				const status = syncStatusList[key]
				return status && status.label
			},
			productSyncStatusClasses(key, syncStatusList) {
				const status = syncStatusList[key]
				if (status) {
					return [
						'product-sync-status', 
						{
							'text-success': status.value === 'success',
							'text-warning': status.value === 'warning',
							'text-danger': status.value === 'error',
						}
					]
				}
				return ''
			}
		}
	}
</script>

<style scoped>
	.product-sync-status {
		padding-left: 15px;
		position: relative;
		display: inline-block;
	}
	.product-sync-status::before {
		content: counter(sub-item, disc);
		font-size: 30px;
		position: absolute;
		left: 0;
		top:-2px;
	}
	.product-sync-status.text-warning {
		color: black !important;
	}
	.product-sync-status.text-warning::before {
		color: orange;
	}
</style>
