<template>
	<span>
		<el-popover
		  placement="top"
		  width="300"
		  trigger="hover">
		 <div v-for="info in infos">
			<small>{{info}}</small>
		  </div>
		  <i slot="reference" class="el-icon-warning-outline mr-1"></i>
		</el-popover>
		{{title}}
	</span>
</template>

<script>
	export default {
		props: {
			infos: {
				type: Array,
				default: (() => []),
				required: true
			},
			title: {
				type: String,
				default : 'Header',
				required: true
			}
		}
	}
</script>

<style>
</style>
